.weekday-wrapper {
 >.weekday-item {
    margin-right: 5px;
    width: 30px;
    height: 20px;
    cursor: pointer;;
    &.inactive {
      background-color: #fff !important;
      border: 1px solid #3399fe !important;
      color: #000 !important;
      height: 21px;
      &:hover {
        background-color: #3399fe !important;
        color: #fff !important;
      }
    }
  }
}

.date-picker {
  border-radius: 8px;
  border: 1px solid black;
  padding: 4px;
  padding-left: 12px;
}

.upload-modal {
  padding: 32px;
  border: 1px dashed #D0D5DD;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  >p.desc {
    text-align: center;
    margin-bottom: 16px;
  }
}

.image-upload-wrapper {
  display: flex;
  height: 100px;
  >.image-upload {
    width: auto !important;
    margin-right: 6px;
  }
  >.info-block {
    width: 70%;
    display: flex;
    justify-content: end;
    font-size: 14px;
    line-height: 18px;
    .remove-btn {
      color: #DC1F18;
      font-weight: 500;
      font-size: 15px;
      cursor: pointer;
      margin: auto;
    }
  }
}

.err-msg {
  color: #DC1F18;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 4px;
  >p {
    margin-left: 4px;
  }
}

.custom-btn {
  background: #EBF4FF;
  font-weight: 500;
  color: #0068FF;
  border: none;
  border-radius: 8;
  &.bg-none {
    background: none;
    box-shadow: none;
  }
  &.disabled {
    background: #D4D8DD;
    color: #999999;
    path {
      fill: #999999 !important;
      stroke: #999999 !important;
    }
  }
  &.non-text {
    min-width: 52px !important;
  }
}

.booking-status {
  display: flex;
  align-items: center;
  min-width: 173px;
  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    margin-top: 0px;
    height: 16px;
    border-radius: 100%;
    background-color: #69b6d5;
    margin-right: 10px;
  }
  &.pending::before {
    background-color: #C5C9CC !important;
  }
  &.contacted::before {
    background-color: #0068FF !important;
  }
  &.checked_in::before {
    background-color: #26AA53 !important;
  }
  &.canceled::before {
    background-color: #D70000 !important;
  }
}
.restaurant-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0px 120px 0px 200px;
  gap: 24px;
  .restaurant-info {
    cursor: pointer;
    transition: transform .3s;
    width: 18%;
    img {
      object-fit: contain !important;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.restaurant-form-title {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  >span {
    margin-right: 8px;
    width: 14px;
    height: 14px;
  }
}

.restaurant-form-note {
  display: flex;
  align-items: center;
  color: #767A7F;
  font-size: 13px;
  margin-top: 2px;
  >span {
    width: 14px;
    margin-right: 4px;
  }
}